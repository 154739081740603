import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Row, Col, ProductBookCard, Button, WhatNext, Video } from '../../components/global'
import config from '../../siteConfig'

const H200 = () => (
  <Layout>
    <SEO title="The CAN Bus Troubleshooting Trainer, H-200" />
    <div className="container container-last">
      <h1>
        The CAN Bus
        <br />
        Troubleshooting Trainer, H-200
      </h1>
      <p className="boost">
        Learn simple electrical test procedures to restore CAN Bus operation and repair common
        on-board computer problems affecting CAN Bus operation.
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="The CAN Bus Troubleshooting Trainer, H-200"
            titleSmall={true}
            step="Hands-on Kit"
            animate={false}
            price={199.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p className="demote">
            <strong>
              Before purchasing this product, make sure you own{' '}
              <Link to="/products/h-111a">The Starter Kit H-111A</Link> as it is required to own
              this kit.
            </strong>
          </p>
          <p>
            The CAN Bus Trainer allows a technician to experience a total of 48 CAN Bus problems in
            8-12 hours of training with hands-on practice troubleshooting to find the problem. This
            training program is designed for home study or tech school curriculum.
          </p>
          <p>
            <Button
              href="/products/circuit-board-trainer-inventory-clearance"
              text="See Purchasing Options"
              type="secondary"
            />
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">See The CAN Bus Troubleshooting Trainer, H-200 in action.</p>
      <Video src="https://www.youtube.com/embed/_Mb2mYUJG3s" />
      <hr />
      <p>
        Many network wiring failures occur and the CAN Bus network still measures 60 ohms at the
        DLC. A 60 ohm reading may hide a network failure that kills CAN Bus operation. Learn how to
        identify these problems and repair them with a digital ohmmeter.
      </p>
      <Row>
        <Col width="68%" align="left">
          <p>The Can Bus Troubleshooting Trainer, H-200 contains:</p>
          <ul>
            <li>1 - printed circuit board H-PCB200 (shown above)</li>
            <li>1 - Resistor Bag H-RB200</li>
            <li>1 - Student Workbook H-WB200</li>
            <li>1 - Instructor Guide H-IG200</li>
          </ul>
        </Col>
        <Col width="30%" align="left">
          <Image file="h-200.jpg" />
        </Col>
      </Row>
      <hr />
      <WhatNext />
    </div>
  </Layout>
)

export default H200
